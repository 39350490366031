import React, { useEffect } from 'react';
import { EnvironmentsEnum } from '@elrondnetwork/dapp-core/types';
import {
  NotificationModal,
  SignTransactionsModals,
  TransactionsToastList
} from '@elrondnetwork/dapp-core/UI';
import { DappProvider } from '@elrondnetwork/dapp-core/wrappers';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Layout } from 'components';
import { apiTimeout, walletConnectV2ProjectId } from 'config';
import { PageNotFound, Unlock } from 'pages';
import { routeNames, routes } from 'routes';
import { deleteValue, setValue } from 'utils/indexeddb';

export const App = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const connectCallbackUrl = urlSearchParams.get('connectCallbackUrl');

  useEffect(() => {
    (async () => {
      connectCallbackUrl && (await setValue('connect', connectCallbackUrl));
    })().then();
  }, []);

  return (
    <Router>
      <DappProvider
        environment={EnvironmentsEnum.testnet}
        customNetworkConfig={{
          name: 'customConfig',
          apiTimeout,
          walletConnectV2ProjectId
        }}
      >
        <Layout>
          <TransactionsToastList />
          <NotificationModal />
          <SignTransactionsModals className='custom-class-for-modals' />
          <Routes>
            <Route path={routeNames.unlock} element={<Unlock />} />
            {routes.map((route, index) => (
              <Route
                path={route.path}
                key={'route-key-' + index}
                element={<route.component />}
              />
            ))}
            <Route path='*' element={<PageNotFound />} />
          </Routes>
        </Layout>
      </DappProvider>
    </Router>
  );
};
