import React from 'react';
import {
  useGetAccountInfo,
  useGetIsLoggedIn
} from '@elrondnetwork/dapp-core/hooks';
import { logout } from '@elrondnetwork/dapp-core/utils';
import { Button, Nav, Navbar as BsNavbar, NavItem } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ReactComponent as ElrondLogo } from 'assets/img/elrond.svg';
import { dAppName } from 'config';
import { routeNames } from 'routes';
import { deleteValue, useGetIndexedDBValue } from 'utils/indexeddb';

export const Navbar = () => {
  const confirmLink = useGetIndexedDBValue('connect');
  const isLoggedIn = useGetIsLoggedIn();
  const { address } = useGetAccountInfo();
  console.log(useGetAccountInfo());
  const handleConfirm = async () => {
    await deleteValue('connect');
    if (confirmLink) {
      window.location.href = confirmLink + `?walletAddress=${address}`;
    }
  };

  const handleLogout = async () => {
    await logout(`${window.location.origin}/unlock`);
  };

  return (
    <BsNavbar className='bg-white border-bottom px-4 py-3'>
      <div className='container-fluid'>
        <Link
          className='d-flex align-items-center navbar-brand mr-0'
          to={isLoggedIn ? routeNames.dashboard : routeNames.home}
        >
          <ElrondLogo className='elrond-logo' />
          <span className='dapp-name text-muted'>{dAppName}</span>
        </Link>

        <Nav className='ml-auto'>
          {isLoggedIn && (
            <NavItem>
              <button className='btn btn-link mr-3' onClick={handleLogout}>
                Close
              </button>
              {confirmLink && (
                <Button
                  className='text-capitalize'
                  onClick={handleConfirm}
                  variant='success'
                >
                  Confirm connection
                </Button>
              )}
            </NavItem>
          )}
        </Nav>
      </div>
    </BsNavbar>
  );
};
