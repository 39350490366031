import { useEffect, useState } from 'react';
import { DBSchema, openDB } from 'idb';

interface CallbackURLScheme extends DBSchema {
  callbackUrlStore: {
    key: string;
    value: string;
  };
}

const dbPromise = openDB<CallbackURLScheme>('dappAiroDatabase', 1, {
  upgrade(db) {
    db.createObjectStore('callbackUrlStore');
  }
});

const getValue = async (key: string): Promise<string | undefined> => {
  try {
    const db = await dbPromise;
    const tx = db.transaction('callbackUrlStore', 'readonly');
    const store = tx.objectStore('callbackUrlStore');

    const value = await store.get(key);

    await tx.done;

    return value;
  } catch (error) {
    console.error(`Error getting value for key "${key}":`, error);
  }
};

const useGetIndexedDBValue = (key: string): string | undefined => {
  const [value, setValue] = useState<string | undefined>();

  useEffect(() => {
    (async () => {
      const dbValue = await getValue(key);
      setValue(dbValue);
    })().then();
  }, []);

  return value;
};

const setValue = async (key: string, value: string): Promise<void> => {
  try {
    const db = await dbPromise;
    const tx = db.transaction('callbackUrlStore', 'readwrite');
    const store = tx.objectStore('callbackUrlStore');

    await store.put(value, key);

    await tx.done;
  } catch (error) {
    console.error(`Error setting value for key "${key}":`, error);
  }
};

const deleteValue = async (key: string): Promise<void> => {
  try {
    const db = await dbPromise;
    const tx = db.transaction('callbackUrlStore', 'readwrite');
    const store = tx.objectStore('callbackUrlStore');

    await store.delete(key);

    await tx.done;
  } catch (error) {
    console.error(`Error deleting value for key "${key}":`, error);
  }
};

export { deleteValue, getValue, setValue, useGetIndexedDBValue };
